












































import {
  Component, Vue, Watch, Prop,
} from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';
import * as types from '@/store/types';
import { Workspace } from '@/store/workspace/models';
import { required } from 'vuelidate/lib/validators';
import { Validations } from 'vuelidate-property-decorators';

const workspaceNamespace: string = 'workspace';
const userNamespace: string = 'user';

@Component({
  components: {},
})
export default class EditWorkspace extends Vue {
  @Prop({ default: true }) dialog: any;

  @Getter(types.GET_WORKSPACE, { namespace: workspaceNamespace })
  getWorkspace: Workspace | undefined;

  @Getter(types.GET_USER, { namespace: userNamespace }) getUser: any;

  @Action(types.UPDATE_WORKSPACE, { namespace: workspaceNamespace }) updateWorkspace: any;

  @Watch('getWorkspace')
  onWorkspaceChange() {
    this.init();
  }

  private workspaceName: string = '';

  private editMode: boolean = false;

  get isOwner() {
    if (this.getWorkspace && this.getWorkspace.owner && this.getUser) {
      return this.getUser.id === this.getWorkspace.owner.id;
    }
    return false;
  }

  @Validations()
  validations = {
    workspaceName: {
      required,
    },
  };

  get workspaceNameErrors() {
    const errors: any[] = [];
    if (!this.$v.workspaceName.$dirty) return errors;
    if (!this.$v.workspaceName.required) errors.push(this.$t('required_field'));
    return errors;
  }

  get isAllowToSubmit(): boolean {
    return (
      this.$v.$invalid
      || !this.$v.workspaceName.$dirty
      || this.workspaceName === this.getWorkspace?.name
    );
  }

  init() {
    if (this.getWorkspace) {
      this.workspaceName = this.getWorkspace.name;
    }
  }

  closeDialog() {
    this.$emit('close');
  }

  created() {
    this.init();
  }

  submit() {
    this.$v.$touch();

    if (this.isAllowToSubmit) return;
    const w = this.getWorkspace;
    if (w) {
      w.name = this.workspaceName.trim();
      this.updateWorkspace(w);
    }
    this.closeDialog();
  }
}
