














import {
  Component, Vue,
} from 'vue-property-decorator';
import Toolbar from '@/components/shared/Toolbar.vue';
import Breadcrumb from '@/components/shared/Breadcrumb.vue';

@Component({
  components: {
    Toolbar,
    Breadcrumb,
  },
})
export default class WorkspaceToolbar extends Vue {
}
