








































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';
import * as types from '@/store/types';
import { Workspace } from '@/store/workspace/models';
import EditWorkspace from '@/components/workspaceSetting/EditWorkspace.vue';

const workspaceNamespace: string = 'workspace';
const userNamespace: string = 'user';

@Component({
  components: {
    EditWorkspace,
  },
})
export default class OverviewWorkspaceSetting extends Vue {
  @Getter(types.GET_WORKSPACE, { namespace: workspaceNamespace })
  getWorkspace: Workspace | undefined;

  @Getter(types.GET_USER, { namespace: userNamespace }) getUser: any;

  @Action(types.UPDATE_WORKSPACE, { namespace: workspaceNamespace }) updateWorkspace: any;

  @Action(types.REMOVE_WORKSPACE, { namespace: workspaceNamespace }) removeWorkspace: any;

  private editDialog: boolean = false;

  private workspaceName: string = '';

  private confirmDeleteWorkspace: boolean = false;

  get isOwner() {
    if (this.getWorkspace && this.getWorkspace.owner && this.getUser) {
      return this.getUser.id === this.getWorkspace.owner.id;
    }
    return false;
  }

  get numberOfMembers(): number {
    if (this.getWorkspace && this.getWorkspace.members) {
      return this.getWorkspace.members.length;
    }
    return 0;
  }

  get numberOfFlows(): number {
    if (this.getWorkspace && this.getWorkspace.flows) {
      return this.getWorkspace.flows.length;
    }
    return 0;
  }

  async deleteWorkspace() {
    if (this.confirmDeleteWorkspace && this.getWorkspace) {
      const status = await this.removeWorkspace(this.getWorkspace.id);
      if (status && status === 200) {
        this.$router.push('/');
      }
    }
  }
}
