var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t('add_member'))+" ")]),_c('v-card-text',[_c('v-text-field',{attrs:{"label":_vm.$t('email'),"outlined":"","dense":"","append-outer-icon":_vm.email ? 'mdi-send' : '',"clearable":"","error-messages":_vm.emailErrors,"loading":_vm.getLoading,"loader-height":"3","disabled":_vm.getLoading,"autofocus":""},on:{"click:append-outer":function($event){return _vm.addMember()},"blur":function($event){return _vm.$v.email.$touch()},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.addMember()}},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})],1),_c('v-divider'),_c('v-card-text',[_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t('members'))+" "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":_vm.$t('search'),"single-line":"","hide-details":"","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"dense":"","headers":_vm.headers,"items":_vm.getAllMembers,"item-key":"id","items-per-page":10,"search":_vm.search,"footer-props":{
            showFirstLastPage: true,
            itemsPerPageText: _vm.$t('rows_per_page'),
          }},scopedSlots:_vm._u([{key:"item.avatar",fn:function(ref){
          var item = ref.item;
return [_c('Avatar',{attrs:{"getUser":item,"size":"30","fontSizeClass":"12"}})]}},{key:"item.email",fn:function(ref){
          var item = ref.item;
return [(_vm.isYou(item.id))?_c('span',{staticClass:"caption font-italic pr-2"},[_vm._v(" ("+_vm._s(_vm.$t('you'))+")")]):_vm._e(),_vm._v(" "+_vm._s(item.email)+" ")]}},{key:"item.role",fn:function(ref){
          var item = ref.item;
return [(item.isOwner)?_c('span',{staticClass:"caption "},[_vm._v(" "+_vm._s(_vm.$t('workspace_admin'))+" ")]):_c('span',{staticClass:"caption"},[_vm._v(" "+_vm._s(_vm.$t('member'))+" ")])]}},{key:"item.actions",fn:function(ref){
          var item = ref.item;
return [(item.canRemoveMember)?_c('div',[_c('DeleteMemberDialog',{attrs:{"dialog":_vm.deleteDialog,"member":item,"workspace":_vm.getWorkspace,"isOwner":_vm.isOwnerRemove},on:{"close":function($event){_vm.deleteDialog = false}}}),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"error","icon":""},on:{"click":function($event){_vm.deleteDialog = !_vm.deleteDialog}}},on),[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v(" mdi-location-exit ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('leave')))])])],1):_vm._e()]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }