










import {
  Component, Vue, Prop, Watch,
} from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import * as types from '@/store/types';
import { emptyWorkspace, Workspace } from '@/store/workspace/models';

const namespaceUser: string = 'user';
const workspaceNamespace: string = 'workspace';

@Component
export default class Breadcrumb extends Vue {
  @Prop({ default: '' }) title!: string;

  @Getter(types.GET_USER, { namespace: namespaceUser }) getUser: any;

  @Getter(types.GET_USER_WORKSPACES, { namespace: workspaceNamespace }) getWorkspaces: any;

  private workspace: Workspace = emptyWorkspace;

  created() {
    this.setWorkspace();
  }

  @Watch('getWorkspaces')
  onWorkspaceChanage() {
    this.setWorkspace();
  }

  setWorkspace() {
    const { workspaceId } = this.$route.params;

    if (this.getWorkspaces && workspaceId) {
      const workspace = this.getWorkspaces.find((w: any) => {
        if (w.id === workspaceId) {
          return w;
        }
        return '';
      });
      if (workspace) {
        this.workspace = workspace;
      }
    }
  }
}
