






































import { Component, Vue } from 'vue-property-decorator';
import Members from '@/components/workspaceSetting/Members.vue';
import Overview from '@/components/workspaceSetting/Overview.vue';
import { Action, Getter } from 'vuex-class';
import Toolbar from '@/components/workspaceSetting/Toolbar.vue';
import * as types from '@/store/types';

const workspaceNamespace: string = 'workspace';

@Component({
  components: {
    Members,
    Toolbar,
    Overview,
  },
})
export default class WorkspaceSetting extends Vue {
  @Action(types.FETCH_WORKSPACE, { namespace: workspaceNamespace }) fetchWorkspace: any;

  @Getter(types.GET_WORKSPACE, { namespace: workspaceNamespace }) getWorkspace: any;

  async created() {
    const { workspaceId } = this.$route.params;
    await this.fetchWorkspace(workspaceId);
  }
}
