








































import { Component, Vue, Prop } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import * as types from '@/store/types';

const workspaceNamespace: string = 'workspace';
const userNamespace: string = 'user';

@Component
export default class DeleteMemberDialog extends Vue {
  @Prop({ default: false }) dialog!: boolean;

  @Prop() member!: any;

  @Prop() workspace!: any;

  @Prop() isOwner!: any;

  @Action(types.REMOVE_MEMBER, { namespace: workspaceNamespace }) removeMember: any;

  @Getter(types.GET_USER, { namespace: userNamespace }) getUser: any;

  @Getter(types.GET_WORKSPACE, { namespace: workspaceNamespace }) getWorkspace: any;

  private isLeavedisabled = false;

  closeDialog() {
    this.$emit('close');
  }

  async leaveWorkspace() {
    this.isLeavedisabled = true;
    this.closeDialog();
    const memberId = this.member.id;
    const res = await this.removeMember({ memberId, workspaceId: this.getWorkspace.id });
    if (res === 200) {
      if (memberId === this.getUser.id) {
        this.$router.push('/');
      }
    }
    this.isLeavedisabled = false;
  }
}
